// 
// user.scss
// Use this to write your custom SCSS
//

/* to use bootstrap @mixins these custom props need to be here, not in user-variables */
:root {
  --transition-speed: 300ms;
  --shadow: 0 2px 5px rgba(0,0,0,.2);
  --color-secondary: #{$orange};
  --have-post-fill: #{$orange};
  --have-post-stroke: #{$orange};
  --own-have-post-fill: #{$blue};
  --own-have-post-stroke: #{$blue};
  --need-post-fill: #{lighten($orange, 25)};
  --need-post-stroke: #{$orange};
  --own-need-post-fill: #{lighten($blue, 35)};
  --own-need-post-stroke: #{$blue};
  --area-post-fill: #{lighten($orange, 25)};
  --area-post-stroke: #{$orange};
  --own-area-post-fill: #{lighten($blue, 35)};
  --own-area-post-stroke: #{$blue};
  --browser-component-height: 30vh;
  --top-to-left-border: 1px 0 0 0;
  --color-link: #{$blue};
  --color-bg-hilite: #ffecd7;
  --logo-width: 140px;
  --logo-height: calc(var(--logo-width) / 3.466);
  --fixed-nav-height: calc(var(--logo-height) + 1.325rem); // height of fixed nav
  --approx-footer-height: 25rem; // approximate height of footer
}

@include media-breakpoint-up(md) {
  :root {
    --logo-width: 220px;
    --browser-component-height: 500px;
    --top-to-left-border: 0 0 0 1px;
  }
}

// Additional resets

// hide number spinner arrows
input[type=number] {
  appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  opacity: 0;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: var(--fixed-nav-height); 
}

body {
  padding-top: var(--fixed-nav-height);
}

#skiplink:active, #skiplink:focus {
  position: fixed;
  left: 30px;
  top: 10px;
  z-index: 99999;
}

// Supplements to Bootstrap styles. These are Bootstrap class names.
.nav-item {
  border: 4px solid transparent;
  transition: var(--transition-speed) border-color ease-in-out;
}
.nav-item:hover {
  border-bottom-color: $gray-500;
}
.nav-item.active {
  border-bottom-color: $white;
}
.nav-item .nav-link:focus-visible {
  outline: 1px solid #FFF;
}
.navbar-brand img {
  width: var(--logo-width);
}
.align-items-stretch .card {
  min-height: 100%;
}
.align-items-center > * {
  margin-bottom: 0;
  margin-top: 0;
}
.card-footer {
  text-align: center;
  background: $card-border-color;
  border-top-width: 1px;
}
.card-header *:last-child{
  margin-bottom: 0;
}
.btn {
  box-shadow: var(--shadow);
}
.btn-xs.btn-inline {
  line-height: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding-top: 0;
  padding-bottom: 0;
}
.card-list .active .list-link {
  font-weight: bold;
}
.text-white a {
  color: inherit;
}
.fixed-bottom {
  box-shadow: 0 -1px 8px rgba(0,0,0,0.3);
}
legend.col-form-label {
  font-weight: bold;
}
// style placeholder text
.form-control::placeholder {
  font-style: italic;
  color: var(--info);
}
.card-footer:last-child {
  margin-top: auto;
}
.rounded-0 .card-footer:last-child {
  border-radius: 0;
}

// Dirtmatch utility styles
.dm-narrow-gutters {
  margin-left: -10px;
  margin-right: -10px;
}
.dm-narrow-gutters > [class*=col] {
  padding-left: 10px;
  padding-right: 10px;
}
.dm-col-to-row-gutters {
  margin-left: 0;
  margin-right: 0;
  gap: 10px;
}
.dm-col-to-row-gutters > [class*=col] {
  padding-left: 0;
  padding-right: 0;
}
.dm-text-lg {
  font-size: 1.25rem;
  li {
    margin-top: .25em;
    margin-bottom: .25em;
  }
}
.dm-h0 {
  @include type__size-0;
}
.dm-text-xl {
  @include clamper(1.1, 1.5);
}
.btn.dm-text-xl {
  padding: .4em .8em;
}
.dm-text-underline {
  text-decoration: underline;
}
.dm-text-bold {
  font-weight: bold;
}
.dm-border-y {
  border-style: solid;
  border-width: 8px 0;
}
.dm-border-bottom {
  border-style: solid;
  border-width: 0 0 8px 0;
}
.dm-top-to-left-border {
  border-width: var(--top-to-left-border) !important;
}
.dm-shadow-dark-sm {
  box-shadow: 1px 3px 6px rgba(0,0,0,.12);
}
.dm-no-form-check-indent {
  position: static;
  margin-left: 0;
}
.dm-half-grid-medium {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: center;
}
.dm-btn-cancel {
  background-color: var(--gray-dark);
  color: #FFF;
}
.dm-btn-cancel:hover {
  background-color: #000;
  color: #FFF;
}
.dm-short-content-stretch {
  min-height: calc(100vh - var(--fixed-nav-height) - var(--approx-footer-height));
}
// Dirtmatch-specific components.
.dm-list-squarestyle {
  padding-inline-start: 20px;
}
.dm-list-squarestyle li{
  list-style-type: square;
  margin-bottom: .5em;
}
.dm-list-squarestyle li::marker {
  padding-left: 20px;
  color: $primary;
}
.dm-notification-badge {
  transform: translate(0, -.7em);
  margin-left: 0.15rem;
  font-size: .625rem;
  text-transform: uppercase;
}
.dm-notification-badge:not(.badge-rounded-circle){
  padding: .2rem .4rem;
}
.dm-has-inner-badge {
  position: relative;
  margin-right: .5em;
}
.dm-has-inner-badge .dm-notification-badge {
  position: absolute;
  top: -0.5em;
  transform: scale(80%);
}
.dm-badge-new {
  color: #FFF;
  background-color: $danger;
  --pulse-color-start: #{transparentize($danger,.4)};
  --pulse-color-end: #{transparentize($danger,1)};
}
.dm-pulse {
  box-shadow: 0 0 0 var(--pulse-color-start);
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--pulse-color-start);
  }
  70% {
    box-shadow: 0 0 0 8px var(--pulse-color-end);
  }
  100% {
    box-shadow: 0 0 0 0 var(--pulse-color-end);
  }
}
.dm-ribbon {
  display: inline-block;
  clip-path: polygon(100% 0%, calc(100% - .8rem) 50%, 100% 100%, 0 100%, 0 0);
  padding: 0 1.8em .25em .5em;
  vertical-align: text-bottom;
}
.dm-ribbon small {
  font-size: .7em;
}
.dm-ribbon-success {
  background: var(--success);
  color: #FFF;
}
.dm-inline-bg {
  padding: 0 .5rem;
  margin: 0 -.5rem;
  background: rgb(255,255,255,.8);
}
.dm-inline-bg-secondary {
  background: transparentize($color: $secondary, $amount: .2)
}
a.text-white {
  color: $white;
}
a.text-white:hover {
  color: $primary !important;
}

.home-hero {
  background-image: url('/img/photos/dirt-bottom-sm.jpg'), url('/img/photos/dirt-top-sm.jpg');
  background-size: max(1200px,70vw) auto;
  background-position: 50% 100%, 50% 0%;
  background-repeat: repeat-x;
}

.dm-color-circle-bg {
  background-image: radial-gradient(circle at 25vw 50%, rgba(255, 255, 255, 0.5) 24.8vh, rgba(255, 255, 255, 0.1) 24.9vh, transparent 25vh);
}
@include media-breakpoint-up(md) {
  .dm-color-circle-bg {
    background-image: radial-gradient(circle at 40vw 50%, rgba(255, 255, 255, 0.5) 19.8vw, rgba(255, 255, 255, 0.1) 19.9vw, transparent 20vw);
  }
}

.dm-photo-circle {
  padding-top: 3rem;
  padding-bottom: 5rem;
  overflow: visible clip;
}
.provider .dm-photo-circle {
  padding-bottom: 8rem;
}
.dm-photo-circle::before,
.dm-photo-circle::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 12rem;
  aspect-ratio: 5 / 4;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='520' height='416'%3E%3Cpath d='M103.974 416a260.038 260.038 0 0 1 0-416h312.051a260.038 260.038 0 0 1 0 416Z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='520' height='416'%3E%3Cpath d='M103.974 416a260.038 260.038 0 0 1 0-416h312.051a260.038 260.038 0 0 1 0 416Z'/%3E%3C/svg%3E");
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center right;
  mask-position: center right;
}
.dm-photo-circle::before {
  background: no-repeat url("/img/pro/pro-dirt-hauler.jpg") 100% 50% / contain;
}
.dm-photo-circle::after {
  left: -5px;
  height: 12.8rem;
  z-index: -1;
  background-color: rgba(255, 255, 255, 0.5);
}
.home .dm-photo-circle::before {
  background-image: url("/img/pro/pro-dirt-hauler-cta.jpg");
  clip-path: circle(44% at center);
  top: auto;
  right: -40px;
  bottom: -15px;
  left: auto;
}
.home .dm-photo-circle::after {
  background-color: rgba(255, 255, 255, 0.5);
  clip-path: circle(44% at center);
  top: auto;
  right: -40px;
  bottom: -20px;
  left: auto;
}

@include media-breakpoint-up(md) {
  .dm-photo-circle,
  .provider .dm-photo-circle {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .dm-photo-circle::before,
  .dm-photo-circle::after {
    top: 0;
    right: 0;
    left: auto;
    height: auto;
  }
  .dm-photo-circle::after {
    height: auto;
    right: -16px;
    bottom: -20px;
    left: auto;
  }
  .home .dm-photo-circle::before,
  .home .dm-photo-circle::after {
    clip-path: none;
  }
  .home .dm-photo-circle::before {
    top: 0;
    right: auto;
    bottom: 0;
    left: 0;
  }
  .home .dm-photo-circle::after {
    top: 0;
    right: auto;
    bottom: -20px;
    left: -16px;
  }
}

.dm-dot-background {
  position: relative;
}
.dm-dot-background::before {
  content: "";
  position: absolute;
  display: block;
  height: 33%;
  bottom: 0;
  width: 100%;
  background-image: radial-gradient($blue 4px, transparent 4px), radial-gradient($blue 4px, transparent 4px);
  background-position: 8px 8px, 24px 24px;
  background-size: 32px 32px;
}
.dm-dot-background.bg-dark::before {
  opacity: .3;
}
.dm-transform-shrink {
  transform: scale(.95);
}
.dm-transform-grow {
  transform: scale(1.05);
}

// Google Recaptcha badge 
//(hide by default)
.grecaptcha-badge { 
    visibility: hidden; 
}

// Google places edit control dropdown 
// (place it above modals)
.pac-container {
    z-index: 1063 !important;
}

.card .icon-circle svg {
  fill: #FFF;
  width: 100%; // needed for safari
}
.card .icon-circle {
  width: 6rem;
  height: 6rem;
  margin: -1rem auto -1rem auto;
  padding: 1rem;
}
@include media-breakpoint-up(md) {
  .card .icon-circle {
    margin: 2rem auto 0 auto;
    padding: 1rem;
  }
}

.provider .search-form button,
.provider .search-form legend {
  @extend .sr-only;
}
.provider .search-form [class*=col] {
  max-width: none;
  flex: auto;
}
@include media-breakpoint-down(sm){
  .provider .search-form {
    background-color: $primary;
    padding: 8px 1rem 0 1rem;
  }
}
@include media-breakpoint-up(md) {
  .provider .search-form {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    width: max(30vw, 20ch);
  }
}
.provider input.form-control {
  box-shadow: var(--shadow);
}
.provider .modal-content input.form-control{
  box-shadow: none;
}

form { 
  accent-color: $primary;
}
footer nav {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-width: var(--top-to-left-border);
}
footer nav a {
  display: inline-block;
  padding: .5em 0;
}

// leave space at bottom of page footer so it's not covered by sticky banner
.dm-has-sticky-banner footer {
  padding-bottom: 7rem;
}
.dm-icon {
  width: 24px;
  height: 24px;
}
.dm-icon-white {
  fill: #FFF;
}
.dm-icon-need.dm-icon-white {
  fill: transparent;
  stroke: #FFF;
}
.dm-icon-have {
  fill: var(--have-post-fill);
  stroke: var(--have-post-stroke);
  stroke-width: 2px;
}
.dm-icon-need {
  fill: var(--need-post-fill);
  stroke: var(--need-post-stroke);
  stroke-width: 2px;
}
.dm-input-with-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 20'%3E%3Cpath data-name='place-24px 1' d='M7 2a5.006 5.006 0 0 0-5 5c0 1.713 1.112 4.309 3.131 7.31.653.97 1.311 1.851 1.87 2.561.549-.7 1.2-1.564 1.842-2.521C10.878 11.332 12 8.722 12 7a5.006 5.006 0 0 0-5-5m0-2a7 7 0 0 1 7 7c0 5.25-7 13-7 13S0 12.25 0 7a7 7 0 0 1 7-7Z' fill='%23CCC'/%3E%3C/svg%3E");
  background-size: auto 50%;
  background-repeat: no-repeat;
  background-position: .7em 50%;
  padding-left: 2.5em;
}

@include media-breakpoint-down(sm){
  .dm-hero-btn {
    padding: 10px 5px;
  }
}
.dm-hero-btn .dm-icon {
  transform: translateY(-1px);
}
.dm-hero-btn:hover .dm-icon,
.dm-hero-btn:focus .dm-icon {
  transition: transform var(--transition-speed);
  transform-origin: 50% 100%;
  transform: scale(1.2);
}

.dm-tagline {
  @include clamper(3, 5);
  height: 1.1em;
  position: relative;
  margin-top: -0.1em;
  left: -.02em;
  --animation-length: 2800ms;
  --animation-overlap: 300ms;
  letter-spacing: 1px;
}
.dm-tagline span {
  position: absolute;
  opacity: 0;
  animation: var(--animation-length) ease forwards fadeinout;
}
.dm-tagline span:nth-child(1){
  animation-delay: 0;
}
.dm-tagline span:nth-child(2){
  animation-delay: calc(var(--animation-length) - var(--animation-overlap));
}
.dm-tagline span:nth-child(3){
  animation-delay: calc(var(--animation-length) * 2 - var(--animation-overlap) * 2);
}
.dm-tagline span:nth-child(4){
  animation-delay: calc(var(--animation-length) * 3 - var(--animation-overlap) * 3);
}
.dm-tagline span:last-child {
  animation: var(--animation-length) ease forwards fadein;
  animation-delay: calc(var(--animation-length) * 4 - var(--animation-overlap) * 4)
}

@keyframes fadeinout {
	0%{
    filter: blur(10px);
		opacity: 0;
	}
	70% {
    filter: blur(0px);
    opacity: 1
	}
  100% {
    filter: blur(0px);
    opacity: 0
	}
}
@keyframes fadein {
	0%{
    filter: blur(10px);
		opacity: 0;
	}
	100% {
    filter: blur(0px);
    opacity: 1
	}
}

// modal close button
.close {
  font-size: 2.5rem;
  line-height: .7em;
}

// Classes applied by Parsley form validation
.parsley-errors-list {
  font-size: smaller;
  padding-inline-start: 1em;
  padding-top: .25em;
}
.parsley-errors-list li::marker {
  color: $danger;
}
.input-group .parsley-errors-list {
  width: 100%;
}

@include media-breakpoint-down(md) {
  .modal-body {
    padding: 1.5rem;
  }
}
.sw-theme-default {
  border: 0;

  .nav {
    margin-top: 10px;
  }
  .toolbar {
    padding: .8rem 0 0 0;
  }
  .toolbar > .btn {
    padding: $btn-padding-y-sm $btn-padding-x-lg;
    background-color: var(--primary);
  }
  .toolbar > .btn:focus{
    box-shadow: $btn-focus-box-shadow;
  }
  .toolbar > .btn.sw-btn-prev {
    background-color: var(--gray-dark);
  }
}

.modal-dialog label:not(.form-check-label, .dm-label-optional) {
  font-weight: bold;
}
@include media-breakpoint-down(md) {
  //reduce vertical padding on mobile
  .modal-dialog label:not(.form-check-label) {
    padding-top: 0;
    padding-bottom: .5em;
  }
}

.dm-choice-column {
  position: relative;
  border: 1px solid $input-border-color;
  border-width: var(--top-to-left-border);
}
.dm-choice-column .or{
  position: absolute;
  left: calc(50% - 1.1em);
  top: -.9em;
  font-size: 1rem;
  background: #FFF;
  padding: 4px 6px;
  color: $input-border-color;
  text-transform: uppercase;
}
@include media-breakpoint-up(md) {
  .dm-choice-column .or {
    left: -1.1em;
    top: 40%;

  }
}
.dm-form-check-group > * {
  margin-bottom: .5em;
}
.dm-form-check-group .form-check-label {
  margin-left: .25em;
}
.dm-form-check-group input:is([type="checkbox"],[type="radio"]) {
  transform: scale(1.2)
}

// Empty Parsley error list hangs around in the DOM even after field is valid
.parsley-success + .parsley-errors-list {
  display: none;
}
.parsley-error {
  @extend .is-invalid;
}
.dm-segmented-control {
  display: flex;
  align-items: stretch;
  height: 100%;
  background: $input-border-color;
  padding: 5px;
  border-radius: $input-border-radius-sm;

  input[type=checkbox],
  input[type=radio] {
    margin-right: .5em;
    // position: absolute;
    // clip: rect(0, 0, 0, 0);
    // pointer-events: none;
  }
  .btn {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    padding: 0 1em;
    background: rgba(255, 255, 255, 0.8);
  }
  .btn + .btn {
    margin-left: 10px;
  }
  .btn.active {
    background: $primary;
    color: $white;
  }
  .btn:focus, .btn.focus {
    box-shadow: none;
  }
  label {
    margin-bottom: 0;
  }
}
.dm-img-preview[src^="data"] {
  background: repeating-linear-gradient(
    45deg,
    #DDD,
    #DDD 10px,
    #EEE 10px,
    #EEE 20px
  );
  aspect-ratio: 1;
}
.dm-img-preview + span {
  display: none;
}
.dm-img-preview[src^="data"] + span {
  display: inline-block;
  position: relative;
  left: -100px;
  width: 100px;
  line-height: 100px;
  text-align: center;
  font-size: smaller;
}

.dm-plan-table td b {
  display: block;
  font-size: 1.125em;
}
.dm-plan-table :is(td, th) {
  vertical-align: top;
  padding: .5rem;
  border-bottom: 1px solid $gray-300;
}
.dm-plan-table tfoot td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: none;
}
.dm-plan-table th {
  padding-bottom: 1rem;
  border-bottom-width: 3px;
}
@include media-breakpoint-up(md) {
  .dm-plan-table th {
    width: 50%;
  }
}