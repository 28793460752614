// 
// user-variables.scss
// Use this to overwrite Bootstrap and Landkit variables
//

// Example of a variable override to change Landkit's background color
// Remove the "//" to comment it in and see it in action!
// $font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;

$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$font-family-base: $font-family-sans-serif;

$font-size-base: 1rem;
$font-size-lg: 1.125rem;

$badge-font-size: .8125rem; // 13px instead of bootstrap's 12px (at body font size)

$list-link-font-size: inherit;

// resets all to default Bootstrap 
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;
// resets default Bootstrap 
$alert-bg-level:                    -10 !default;
$alert-color-level:                 6 !default;
// darker border color 
$alert-border-level:                -4 !default;

$red: #E83F53;
$blue: #2772A1;
$orange: #FEA850;
$green: #3d7853;
$primary: $blue;
$secondary: $orange;
$info: $gray-400;
$success: $green;
$danger: $red;

$body-color: #2E2626;
$line-height-base: 1.375 !default;

/* Bootstrap-provided breakpoints for reference:
  $grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
  ) !default;
*/

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: 620px,
  md: 720px,
  lg: 960px,
  xl: 1200px) !default;

// The default max-width for .modal-dialog; does not adjust at breakpoints
$modal-md: min(36em, 70vw);


$font-weight-bold: 700;
