//
// position.scss
// Extended from Bootstrap
//

.top-0 {
  top: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.left-0 {
  left: 0 !important;
}

.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


// Responsive positions

@each $breakpoint, $value in $grid-breakpoints {
  @if ($breakpoint !='xs') {
    @include media-breakpoint-up(#{$breakpoint}) {
      @each $position in $positions {
        .position-#{$breakpoint}-#{$position} {
          position: $position !important;
        }
      }
    }
  }
}
