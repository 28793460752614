//
// visibility.scss
// Extended from Bootstrap
//

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 1 !important;
}