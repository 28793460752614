//
// overlay.scss
// Theme mixin
//

@mixin clamper($minFontRem, $maxFontRem) {

	$pixelsPerRem: 16;

	// $minWidthRem: 360 / $pixelsPerRem;
	$minWidthRem: 320 / $pixelsPerRem;
	$maxWidthRem: 1200 / $pixelsPerRem;

	$slope: ($maxFontRem - $minFontRem) / ($maxWidthRem - $minWidthRem);
	$slopePercent: $slope*100;
	$yAxisIntersection: -1*$minWidthRem * $slope + $minFontRem;
	$fallbackSize: ($maxFontRem - $minFontRem)/2 + $minFontRem; // halfway between min and max
	
	font-size: #{$fallbackSize}rem;
	font-size: clamp(#{$minFontRem}rem, calc(#{$yAxisIntersection}rem + #{$slopePercent}vw), #{$maxFontRem}rem);

}

@mixin type__size-0 {
	@include clamper(2.5, 4);
}

@mixin type__size-1 {
	@include clamper(2.25, 2.5);
}

@mixin type__size-2 {
	@include clamper(2, 2.25);
}

@mixin type__size-3 {
	@include clamper(1.75, 2);
}

@mixin type__size-4 {
	@include clamper(1.5, 1.75);
}

@mixin type__size-5 {
	@include clamper(1.125, 1.25);
}