//
// type.scss
// Extended from Bootstrap
//

// Headings
h1, h2, h3, h4, h5
.h0, .h1, .h2, .h3, .h4, .h5 {
  font-weight: $font-weight-bold;
  letter-spacing: $headings-letter-spacing;
  line-height: 1.1;
}

.h0 {
  @include type__size-0;
  line-height: 1;
}
h1, .h1 {
  @include type__size-1;
}
h2, .h2 {
  @include type__size-2;
}

h3, .h3 {
  @include type__size-3;
}

h4, .h4 {
  @include type__size-4;
  letter-spacing: 0;
}

h5, .h5 {
  @include type__size-5;
  font-weight: 500;
  letter-spacing: 0;
}

h6, .h6 {
  line-height: $h6-line-height;

  &.text-uppercase {
    letter-spacing: .08em;
  }
}

// Display

.display-1, .display-2, .display-3, .display-4 {
  letter-spacing: $display-letter-spacing;
}

.display-1 {
  line-height: $display1-line-height;

  @include media-breakpoint-down(md) {
    font-size: $display1-size-md;
  }
}

.display-2 {
  line-height: $display2-line-height;

  @include media-breakpoint-down(md) {
    font-size: $display2-size-md;
  }
}

.display-3 {
  line-height: $display3-line-height;

  @include media-breakpoint-down(md) {
    font-size: $display3-size-md;
  }
}

.display-4 {
  line-height: $display4-line-height;

  @include media-breakpoint-down(md) {
    font-size: $display4-size-md;
  }
}


// Lead

.lead {

  @include media-breakpoint-down(md) {
    font-size: $lead-font-size-md;
  }
}


// Blockquote

.blockquote-img {
  max-height: 2.5rem;
  max-width: 100%;
  width: auto;
}

.blockquote-footer {

  &::before {
    display: none;
  }
}


// Social list

.list-social-icon {
  max-width: 1.25rem;
  height: auto;
}


// Dividers

.hr-sm {
  width: 60px;
  border-top-width: $border-width * 2;
}

.hr-md {
  width: 100px;
  border-top-width: $border-width * 2;
}


// Code

code {
  line-height: $code-line-height;
}


p:last-child {
  margin-bottom: 0;
}